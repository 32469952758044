import React , { useEffect } from "react"
import SEO from "../../components/SEO/SEO"

import Layout from "../../components/layout"
import Container from "../../components/container/container"
import Gumshoe from "gumshoejs"

const ClarificationOfCertainTermsAndExplanationOfInformationSecurityAspects = () => {

   useEffect(() => {
    var myyElement = document.getElementById("js-sticky");

    window.addEventListener('scroll', function (evt) {
      var Positionsss =  GetTopLeft ();
      if (Positionsss.toppp  > 95)	{ myyElement.style.position="relative"; myyElement.style.top = "0px"; }
      else							{ myyElement.style.position="fixed";	myyElement.style.top = "90px";}
    });

    function GetOffset (object, offset) {
      if (!object) return;
      offset.x += object.offsetLeft;       offset.y += object.offsetTop;
      GetOffset (object.offsetParent, offset);
    }
    function GetScrolled (object, scrolled) {
      if (!object) return;
      scrolled.x += object.scrollLeft;    scrolled.y += object.scrollTop;
      if (object.tagName.toLowerCase () !== "html") {          GetScrolled (object.parentNode, scrolled);        }
    }
    function GetTopLeft () {
      var offset = {x : 0, y : 0};		GetOffset (myyElement.parentNode, offset);
      var scrolled = {x : 0, y : 0};		GetScrolled (myyElement.parentNode.parentNode, scrolled);
      var posX = offset.x - scrolled.x;	var posY = offset.y - scrolled.y;
      return {lefttt: posX , toppp: posY };
    }

    var gumshoeElement = document.getElementById("js-article-menu");

    if(gumshoeElement !== null) {
      var spy = new Gumshoe('#js-article-menu a', {
      offset: 40, // how far from the top of the page to activate a content are
      });
    }
  })

  return (
    <Layout>
      <SEO
        title="Clarification of certain terms and explanation of information security aspects"
        desc="Objectives of TR-069 specification developers, Security"
        canonical="https://tr069.cloud/articles/clarification-of-certain-terms-and-explanation-of-information-security-aspects/"
        pathname="/articles/clarification-of-certain-terms-and-explanation-of-information-security-aspects"
      />
      <article className="article">
        <div className="article__bg--usp">
          <div className="article-intro">
            <Container>
              <h1 className="article-intro__title">Clarification of certain terms and explanation of information security aspects</h1>
            </Container>
          </div>
        </div>
        <Container>
          <div>
            <nav className="article-menu" id="js-article-menu">
              <ol id="js-sticky">
                <li>
                  <a href="#terms">Terms</a>
                </li>
                <li>
                  <a href="#objectives-of-tr-069-specification-developers">Objectives of TR-069 specification developers</a>
                </li>
                <li>
                  <a href="#security">Security</a>
                </li>
              </ol>
            </nav>
          <div className="article-content">
            <span className="article-content__first-line-bug"></span>
            <div className="fixed-anchor" id="terms">
              <p>
                <strong>CPE / ACS Management Application</strong> is an application that use CPE WAN Management Protocol (CWMP) on CPE and ACS. This application is not specified by CWMP and may be represented as different services, GUI applications, etc.
              </p>
              <p>
                <strong>RPC</strong> is a set of RPC methods used in interaction between ACS and CPE, described in the CWMP specification.
              </p>
              <p>
                <strong>SOAP</strong> - all messages transmitted between ACS and CPE which converted to XML format. The application of SOAP allows you to provide a platform-independent solution, to leave implementation of specific applications.
              </p>
              <p>
                <strong>HTTP</strong> is selected as a transport protocol for SOAP requests due to its popularity. It is supposed that in most cases, the firewall settings imply transmission of traffic through http ports, respectively, implementation of CWMP will not require a significant revision of corporate information security policies SSL / TLS. (The appropriate traffic encryption methods are used during data transfer between CPE and ACS to ensure confidentiality and integrity of transmitted information). Authentication of CWMP-communicating parties using certificates is used.
              </p>
              <p>
                <strong>TCP / IP</strong> - all messages transmitted between ACS and CPE in accordance with TR-069, should be transmitted using TCP protocol to ensure their guaranteed delivery. The use of TCP is also determined by the need to work under NAT conditions.
              </p>
            </div>
            <div className="fixed-anchor" id="objectives-of-tr-069-specification-developers">
              <h2>Objectives of TR-069 specification developers</h2>
              <p>In terms of information security, the developers of TR-069 specification were focused on achievement the following objectives:</p>
              <ul>
                <li>Prevention of falsification of CPE or ACS control functions in case of interaction between CPE and ACS</li>
                <li>Ensuring the confidentiality of connection between CPE and ACS</li>
                <li>Provision of authentication capabilities for each type of transaction</li>
                <li>Prevention of unauthorized use of services</li>
              </ul>
            </div>
            <div className="fixed-anchor" id="security">
              <h2>Security</h2>
              <p>
                For effective and successful goal accomplishment is recommended to use the SSL 3.0 and / or TLS 1.0 protocols mentioned above. Another way of security is common http authentication using username and password. TR-069 defines application of both protocols SSL and TLS from ACS side. It is necessary to ensure that the most convenient for the CPE authentication option is supported. The mechanisms for issuing and revoking of certificates are not defined by TR-069 specification:
              </p>
              <ul>
                <li>
                  Usage of HTTPS is highly recommended (whole communication with the ACS will be encrypted and resistant to eavesdropping)
                </li>
                <li>
                  A proper strict configuration of the device's firewall can improve the security (a range of IP addresses that perform Connection Request should be limited to a safe pool)
                </li>
                <li>
                  Device authentication uses username and password (by default HTTP Digest so the password is not sent publicly)
                </li>
                <li>
                  Usage of unique usernames per device as well as random and individual passwords can significantly improve security
                </li>
                <li>
                  SSL/TLS certificates can be used to mutually verify ACS' and device's identities
                </li>
              </ul>
            </div>
          </div>
        </div>
        </Container>
      </article>
    </Layout>
  )
}

export default ClarificationOfCertainTermsAndExplanationOfInformationSecurityAspects
